//////ナビゲーション
$(function () {
  $('.menu_trigger').on('click', function () {
    $(this).toggleClass('active');
    $('.header_nav_contents').slideToggle();
  });
});
$(function () {
  $('.menu_trigger').click(function () {
    $('.header_contents').toggleClass('active');
  });
});
$('.header_nav_contents a').on('click', function () {
  if (window.innerWidth <= 1200) {
    $('.menu_trigger').click();
  }
});
$(window).on('resize', function () {
  if ('none' == $('.header_nav_contents').css('display')) {
    $('.header_nav_contents').attr('style', '');
  }
}); //////nav_area

jQuery(document).ready(function ($) {
  if (window.matchMedia('(min-width: 1200px)').matches) {
    $(".h_nav_li").hover(function () {
      $(".nav_area").toggleClass("active_s");
      $(this).children(".nav_area").stop().slideToggle();
    });
  } else {
    $(".h_nav_li").click(function () {
      $(".nav_area").toggleClass("active_s");
      $(this).children(".nav_area").slideToggle();
      $(".h_nav_li").not($(this)).children(".nav_area").slideUp();
    });
  }
}); //////footer(sp)

$(function () {
  var width = $(window).width();

  if (width < 670) {
    $('.spnav_btn').click(function () {
      $(this).next('.sp_nav_area').slideToggle();
      $(this).toggleClass("spnav_active");
    });
  }
}); //////ページトップ

$(function () {
  var topBtn = $('#page-top');
  topBtn.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 650) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
}); //////スムーススクロール

$(function () {
  $('a[href^="#"]').click(function () {
    var speed = 1500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({
      scrollTop: position
    }, speed, "swing");
    return false;
  });
}); //////スムーススクロールIE

if (navigator.userAgent.match(/(msie|MSIE) 10/i) || navigator.userAgent.match(/(T|t)rident\/7\./) || navigator.userAgent.match(/Edge\/\d+\.\d+/)) {
  $('body').on("mousewheel", function () {
    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }

    var wd = event.wheelDelta;
    var csp = window.pageYOffset;
    window.scrollTo(0, csp - wd);
  });
} //////リンク


$(document).ready(function () {
  $(".link").click(function () {
    if ($(this).find("a").attr("target") == "_blank") {
      window.open($(this).find("a").attr("href"), '_blank');
    } else {
      window.location = $(this).find("a").attr("href");
    }

    return false;
  });
}); //////スライダー

$(function () {
  $('#slider01').slick({
    infinite: true,
    arrows: true,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    asNavFor: '#slider02',
    prevArrow: '<div class="prev"></div>',
    nextArrow: '<div class="next"></div>',
    responsive: [{
      breakpoint: 640,
      settings: {
        variableWidth: false,
        centerMode: false
      }
    }]
  });
  $('#slider02').slick({
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: true,
    asNavFor: '#slider01',
    focusOnSelect: true
  });
}); //////スライダー

$(function () {
  $('.track_slider').slick({
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: '<div class="prev_arrow"></div>',
    nextArrow: '<div class="next_arrow"></div>',
    responsive: [{
      breakpoint: 900,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 640,
      settings: {
        slidesToShow: 1
      }
    }]
  });
}); 
//////modal
$(function () {
  var winScrollTop;
  $('.js-modal-open').each(function () {
    $(this).on('click', function () {
      winScrollTop = $(window).scrollTop();
      var target = $(this).data('target');
      var modal = document.getElementById(target);
      $(modal).fadeIn();
      return false;
    });
  });
  $('.js-modal-close').on('click', function () {
    $('.js-modal').fadeOut();
    $('body,html').stop().animate({
      scrollTop: winScrollTop
    }, 100);
    return false;
  });
}); 
//////tab
document.addEventListener('DOMContentLoaded', function () {
  var tabs = document.getElementsByClassName('cal_link_a');

  for (var i = 0; i < tabs.length; i++) {
    tabs[i].addEventListener('click', tabSwitch);
  }

  function tabSwitch() {
    document.getElementsByClassName('is_active')[0].classList.remove('is_active');
    this.classList.add('is_active');
    document.getElementsByClassName('is_show')[0].classList.remove('is_show');
    var arrayTabs = Array.prototype.slice.call(tabs);
    var index = arrayTabs.indexOf(this);
    document.getElementsByClassName('tab_content')[index].classList.add('is_show');
  }
});

//////select
function multi_filter(h, t, s, r = ''){
  //****** 変数定義 ******
  let hidden = h;
  let target = t;
  let targets = document.querySelectorAll(target);
  let selects = document.querySelectorAll(s);
  let result = document.querySelector(r);
  let result_msg = '';
  
  if (selects) {
    let selects_length = selects.length;
    //****** セレクトボックスごとにchangeイベントをチェック ******
    for (let i = 0; i < selects_length; i++) {
      selects[i].addEventListener('change', () => {
        let targets_length = targets.length;
        let hidden_num = 0;
        //*** targetを順番に調べる ***
        for (let ii = 0; ii < targets_length; ii++) {
          //*** 非表示クラスを一旦削除 ***
          targets[ii].classList.remove(hidden);
          
          destroyPagenation();// ページネーションの破棄

          //*** セレクトボックスの数だけ総当たりで検索 ***
          for (let iii = 0; iii < selects_length; iii++) {
            //*** selectのname値 / optionのvalue値 / targetの文字列を取得 ***
            let value = selects[iii].value;
            let name = selects[iii].getAttribute('name');
            let item_data = targets[ii].getAttribute('data-' + name);
            //*** value値が存在＆all以外＆一致しない / 非表示クラスなしのとき：非表示クラスを付与 ***
            if (value && value !== 'all' && value !== item_data && !targets[ii].classList.contains(hidden)) {
              targets[ii].classList.add(hidden);
              hidden_num++;
            }
          }
          setPagenation(); //ページネーションの初期化
        }
        //****** 結果メッセージ ******
        if (result) {
          //*** 該当なし ***
          if (hidden_num === targets_length) {
            result_msg = '見つかりませんでした。';
          }
          result.innerText = result_msg;
        }
      });
    }
  }
}
// ページネーションの破棄
function destroyPagenation() {
	$(".pagination").jPages("destroy");
}
// ページネーションの初期化
function setPagenation() {
  $(".pagination").jPages({
    containerID: "news_item",
    perPage: 10,
    startPage: 1,
    startRange: 1,
    midRange: 4,
    first: "最初へ",
    previous: "前へ",
    next: "次へ",
    last: "最後へ"
  });
}
multi_filter('serch_hide', '.post_item_col', '.serch_link', '.serch_txt');

$(function(){
    $(".pagination").jPages({
    containerID: "news_item",
    perPage: 10,//1ページに表示するアイテム数
    startPage: 1,//最初に表示されるページ
    startRange: 1, //常に表示される最初のリンク数
    midRange: 4,//一度に表示するリンク数
    first: "最初へ", //最初へ
    previous: "前へ", //前へ
    next: "次へ", //次へ
    last: "最後へ" //最後へ
  });
});



